<template>

<div class="featured-product-row" v-if="!loading && featured_products.length > 0">
	<h2 class="section-title">{{ title }}</h2>
	<div class="products">
		<div class="columns products-grid columns-3">
			<product v-for="(product, index) in featured_products" :product="product" :featured="true" :key="'featured-product-' + index"></product>
		</div>
	</div>
</div>

</template>

<script>
export default {
	name: 'featured-product-row',
	props: ['config', 'options', 'link_type', 'link_name'],
	data() {
		return {
			loading: true,
			featured_products: []
		}
	},
	created() {
		this.fetchData()
	},
	computed: {
		title() {
			if (!this.config) {
				return 'Best Sellers'
			} else {
				return this.getTemplateData.pageSections[this.localIndex].options.title || 'Best Sellers'
			}
		},
		localIndex() {
			if (!this.config) return
			return this.config.index - 1
		}
	},
	methods: {
		fetchData() {
            if (this.$route.name == "Home") {
                this.updateSiteLoading({ value: 'featured-product-row' })
            }
            this.updateSiteLoading({ value: 'featured-product-row', remove: true })
			this.apiCall({ destination: 'get_list_featured_products', data: { link_type: this.link_type, link_name: this.link_name } }).then(obj => {
                this.featured_products = obj.featured_products
				this.loading = false
            }).catch(error => {
                this.updateSiteLoading({ value: 'featured-product-row', remove: true })
                console.log(error)
            })
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
