<template>

<div class="featured-collections" v-if="featured_collections.length > 0">
	<h2 class="section-title featured-collections-title">
		{{ title }}
	</h2>
	<div class="featured-collections columns" :class="[ 'columns-' + columns ]">
		<template v-for="(featured_collection, index) in featured_collections">
			<router-link v-if="featured_collection.link_type" class="featured-collection tile" :to="'manufacturer/' + featured_collection.link_value">
				<img :src="featured_collection.image_src" :key="index" :alt="featured_collection.description + ' Badge'" />
			</router-link>
			<router-link v-else class="featured-collection tile" :to="featured_collection.link_value">
				<img :src="featured_collection.image_src" :key="index" :alt="featured_collection.description + ' Badge'"  />
			</router-link>
		</template>
	</div>
</div>

</template>

<script>
export default {
	name: 'featured-collections',
	props: ['config', 'options'],
	data() {
		return {
			featured_collections: []
		}
	},
	computed: {
		title() {
			return this.getTemplateData.pageSections[this.localIndex].options.title
		},
		rows() {
			return this.getTemplateData.pageSections[this.localIndex].options.rows
		},
		columns() {
			return this.getTemplateData.pageSections[this.localIndex].options.columns
		},
		localIndex() {
			if (!this.config) return
			return this.config.index - 1
		}
	},
	created() {
		this.fetchData()
	},
	methods: {
		fetchData() {
			var data = {
				rows: this.rows,
				columns: this.columns
			}
            if (this.$route.name == "Home") {
                this.updateSiteLoading({ value: 'featured-collections' })
            }
			this.apiCall({ destination: 'get_list_featured_collections', data: data }).then(obj => {
                this.updateSiteLoading({ value: 'featured-collections', remove: true })
                this.featured_collections = obj.featured_collections
            }).catch(error => {
                this.updateSiteLoading({ value: 'featured-collections', remove: true })
                console.log(error)
            })
		}
	}
}
</script>

<style lang="scss" scoped>
.featured-collections { grid-gap: 1rem; }
</style>
