<template>
<div class="main" :class="sectionsClass">
    <h1 v-if="getUser.user_id">My Account</h1>
	<div :class="[!getUser.user_id ? 'account-content-login' : '', window.width < 768 ? 'mobile-account-content' : 'account-content']">
        <ul v-if="getUser.user_id" :class="[window.width < 768 ? 'mobile-menu' : 'menu', 'left-account-tabs', !isAccount ? 'hide-mobile-menu' : '']">
			<li><router-link to="/myaccount/profile"><span class="material-symbols-outlined">&#xe7fd</span> My Profile</router-link></li>
            <li><router-link to="/myaccount/orders"><span class="material-symbols-outlined">&#xf569</span> My Orders</router-link></li>
            <li><router-link to="/myaccount/lists"><span class="material-symbols-outlined">&#xe0ee</span> My Lists</router-link></li>
			<li v-if="getClientHasMemberships"><router-link to="/myaccount/membership"><span class="material-symbols-outlined">&#xe8f7</span> Memberships</router-link></li>
			<li v-if="getClientHasWaivers"><router-link to="/myaccount/waivers"><span class="material-symbols-outlined">&#xe746</span> Waivers</router-link></li>
			<li v-if="getClientHasRange"><router-link to="/range"><span class="material-symbols-outlined">&#xe719</span> Range Time</router-link></li>
            <li><router-link to="/myaccount/login?logout=true"><span class="material-symbols-outlined">&#xe9ba</span> Sign Out</router-link></li>
		</ul>

		<div v-else></div>

		<router-link v-if="getUser.user_id && !isAccount && window.width < 768" to="/myaccount" class="back-button-mobile"><span class="material-symbols-outlined">&#xe5e0</span> Back to My Account</router-link>

        <div :class="['account-section-content', { 'mobile-section-content': window.width < 768 }]">
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script>
export default {
	name: 'account',
	data() {
		return {
			memberships: [],
			membership_enabled: false,
			subscription_enabled: false,
			window: {
				width: 0
			}
		}
	},
	computed:{
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return

			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		},
		isAccount() {
			return this.$route.path == '/myaccount'
		}
	},
	created() {
		if (this.$route.query.section) {
			this.$router.push('/myaccount/' + this.$route.query.section)
		}
		if (!this.getMyAccountOptionsSet) {
			this.getAccountOptions()
		}
		if (this.$route.path.indexOf('/waivers') > -1) {
			console.log('waivers')
		} else if (!this.getUser.user_name) {
			if (this.$route.path != '/myaccount/login') {
				this.$router.push('/myaccount/login')
			}
		}
		window.addEventListener('resize', this.handleResize);
        this.handleResize();
   	},
   	destroyed() {
        window.removeEventListener('resize', this.handleResize);
	},
	watch: {
		'$route' (value) {
            this.$gtag.pageview({
                page_path: value.path,
            })
        }
	},
	methods: {
		getAccountOptions() {
			this.apiCall({ destination: 'get_my_account_options' }).then(obj => {
				this.updateMyAccountOptionsSet(true)
				this.membership_enabled = obj.membership_enabled,
				this.subscription_enabled = obj.subscription_enabled
			}).catch(err => {
				console.log(err)
			})
		},
		fetchMemberships() {
			this.apiCall({ destination: 'get_list_memberships' }).then(obj => {
				this.memberships = obj.memberships
			}).catch(err => {
				console.log(err)
			})
		},
		doLogout() {
    		this.apiCall({ destination: 'logout' }).then(obj => {
				sessionStorage.removeItem('fd_token')
				localStorage.removeItem('user_type_code')
				this.$router.push('/myaccount/login')
    		})
    	},
		handleResize() {
            this.window.width = window.innerWidth;
        }
	}
}
</script>

<style lang="scss">

.sign-in-deets {
	ul {
		columns: 2;
	}
}

.account-content {
    display: grid;
    grid-template-columns: 15em auto;
    grid-gap: 1em;
	color: #000;
    .left-account-tabs {
        li {
			list-style: none;
            color: #000;
			border-radius: 0 15px 15px 0;
			filter: drop-shadow(0 0 0.25em rgba(158, 158, 158, 0.4));
            margin-bottom: 0.25em;
            font-size: 1.1em;
			a { 
				display: block;
				padding: 0.75em 1em;
				color: #000;
			}
            .material-symbols-outlined {
                vertical-align: text-bottom;
                padding-right: 0.5em;
            }
			.router-link-active {
				background-color: #fff;
				border-radius: 0 15px 15px 0;
			}
		}
    }
}
.mobile-account-content {
	grid-template-columns: 1fr;
	.mobile-menu {
		li {
			list-style: none;
			text-align: left;
			font-size: 1.5em;
			background-color: #fff;
			filter: drop-shadow(0 0 0.25em rgba(158, 158, 158, 0.4));
			border-radius: 15px;
			margin-bottom: 0.75em;
			margin-left: 0;
			a { 
				display: block;
				padding: 0.5em 1.25em;
				color: #000;
			}
			.material-symbols-outlined {
				padding-right: 1em;
				font-size: 1.25em;
			}
		}
	}
	.hide-mobile-menu {
		display: none;
	}
	.back-button-mobile {
		color: #000;
		text-align: left;
		font-size: 1.25em;
		background-color: #fff;
		border-radius: 15px;
		margin-bottom: 0.75em;
		padding: 0.5em 1.25em;
		display: block;
		.material-symbols-outlined {
			padding-right: 0.5em;
			font-size: 1em;
		}
	}
}

.account-content-login {
	grid-template-columns: 1fr;
}

.account-section-content {
	// padding: 0 1em 2em 1em;
	width: 100%;
	background-color: #fff;
	border-radius: 15px;
	// min-height: 20em;
	filter: drop-shadow(0 0 0.25em rgba(158, 158, 158, 0.4));
}
</style>
