<template>

<ul class="color-picker" v-if="colors.length > 0 && !disable">
	<li v-for="(color, index) in colors" :style="style(color)" @click="$emit('colorClick', color)"></li>
</ul>

<ul class="color-picker" v-else>
	<li class="disabled" v-for="(color, index) in colors" :style="style(color)"></li>
</ul>

</template>

<script>
export default {
	name: 'color-picker',
	props: [ 'colors', 'disable', 'rgb' ],
	data() {
		return {
			localColors: [],
			localDisable: false
		}
	},
	mounted() {
		// console.log(this.colors)
	},
	watch: {
		disable(newVal) {
			this.localDisable = newVal
		}
	},
	methods: {
		style(color) {
			if (this.rgb) {
				return { backgroundColor: "rgb(" + color + ")"}
			} else {
				return { backgroundColor: color }
			}
		},
		computedColor(color) {
			if (rgb) {
				return "rgb(" + color + ")"
			} else {
				return color
			}
		}
	}
}
</script>

<style lang="scss" scoped>
</style>