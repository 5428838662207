<template>

<div class="department-links" v-if="department_links.length > 0">
	<div class="department-links-section columns" :data-section_hash="config.hash" :class="columnsClass" v-if="!config.drag">
		<router-link v-for="(department, index) in department_links" class="department tile" :to="department.link_url" :key="'department-link-' + department.department_id">
			<img :src="department.image_url" :key="index" :alt="department.description" />
			<div class="department-label">{{ department.description }}</div>
		</router-link>
	</div>
</div>

</template>

<script>
export default {
	name: 'department-links',
	props: ['config', 'options'],
	data() {
		return {
			department_links: []
		}
	},
	created() {
		this.fetchData()
	},
	computed: {
		localIndex() {
            return this.config.index - 1
        },
		rows: {
			get() {
				return this.getTemplateData.pageSections[this.localIndex].options.rows
			},
			set(value) {
				this.updateTemplateData({ path: `pageSections[${this.localIndex}].options.rows`, value: value })
			}
		},
		columns: {
			get() {
				return this.getTemplateData.pageSections[this.localIndex].options.columns
			},
			set(value) {
				this.updateTemplateData({ path: `pageSections[${this.localIndex}].options.columns`, value: value })
			}
		},
		columnsClass() {
			return 'columns-' + this.columns
		},
		imageStyle: {
			get() {
				return this.getTemplateData.pageSections[this.localIndex].options.imageStyle
			},
			set(value) {
				this.updateTemplateData({ path: 'pageSections[' + this.localIndex + '].options.imageStyle', value: value })
			}
		}
	},
	methods: {
		fetchData() {
			var data = {
				rows: this.rows,
				columns: this.columns,
				theme: this.imageStyle
			}
            if (this.$route.name == "Home") {
                this.updateSiteLoading({ value: 'department-links' })
            }
			this.apiCall({ destination: 'get_list_department_links', data: data }).then(obj => {
                this.updateSiteLoading({ value: 'department-links', remove: true })
                this.department_links = obj.department_links
            }).catch(error => {
                this.updateSiteLoading({ value: 'department-links', remove: true })
                console.log(error)
            })
		}
	}
}
</script>
