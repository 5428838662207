<template>

<div :class="[ 'custom-content-section' ]" :style="[ sectionStyle ]" :data-section_hash="config.hash" v-if="!config.drag">
	<div :class="[ 'text-section', textDivWidthClass, textDivAlignmentClass ]" v-html="options.content"></div>
</div>

</template>

<script>
export default {
	name: 'custom-content',
	props: ['config', 'options'],
	data() {
		return {
		}
	},
	computed: {
		localIndex() {
			if (!this.config) return
			return this.config.index - 1
		},
		sectionStyle() {
			// var imageSrc = (this.options.backgroundImage.image_src) ? this.options.backgroundImage.image_src : "/img/BannerMockup.jpg"
			var imageSrc = (this.options.backgroundImage) ? this.options.backgroundImage.image_src : ''
			return {
				backgroundImage: "url(" + imageSrc + ")",
				minHeight: this.options.sectionHeight + "px"
			}
		},
		textDivStyle() {
			return {
				// display: (this.options.textDivShow) ? 'block' : 'none',
				backgroundColor: "rgba(" + this.options.textDivBackgroundColor + "," + (this.options.textDivOpacity / 10) + ")",
				color: this.options.color,
				marginLeft: this.options.textDivMarginLeft + "%",
				marginRight: this.options.textDivMarginRight + "%",
				fontFamily: this.options.fontFamily
			}
		},
		textDivWidthClass() {
			return this.getTemplateData.pageSections[this.localIndex].options.textDivWidthClass
		},
		textDivAlignmentClass() {
			return this.getTemplateData.pageSections[this.localIndex].options.textDivAlignmentClass
		}
	},
	mounted() {
		this.convertOembedToIFrame()
	},
	methods: {
		convertOembedToIFrame() {
			let media = document.querySelectorAll('figure.media oembed')
			media.forEach(async element => {
				let url = element.getAttribute('url')
				let api = ''
				if (url.indexOf('vimeo.com') > -1) {
					api = 'https://vimeo.com/api/oembed.json?url='
				} else {
					api = 'https://www.youtube.com/oembed?url='
				}
				await fetch(api + url).then(response => {
					return response.json()
				}).then(obj => {
					let iframe = new DOMParser().parseFromString(obj.html, "text/html").body.firstElementChild
					let ratio = obj.width / obj.height
					iframe.width = 640
					iframe.height = 640 / ratio
					element.parentNode.replaceChild(iframe, element)
				})
			});
		}
	}
}
</script>

<style lang="scss">
.text-section {
	a {
		text-decoration: underline;
	}
	a:hover {
		color: #4abdac;
	}
	h2 {
		background-color: #dfdce3;
		padding: 0.5em;
	}

	h5 {
		font-size: 1em;
		a {
			background-color: #000;
			padding: 0.75em 1.25em;
			color: #fff !important;
			text-decoration: none;
		}
		a:hover {
			background-color: #1f1f1f;
		}
	}
	h6 {
		font-size: 1em;
		a {
			background-color: #fff;
			padding: 0.75em 1.25em;
			color: #000 !important;
			text-decoration: none;
		}
		a:hover {
			background-color: #e8e8e8;
		}
	}
/*@media (max-width: 768px) {
	margin-left: 0 !important;
}*/
}
</style>
