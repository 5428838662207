import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Catalog from './views/Catalog.vue'
import Product from './views/Product.vue'
import Account from './views/Account.vue'
//import Login from './views/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        name: 'Category',
        path: '/category/:link_name',
        component: Catalog,
        props: true,
    },
    {
        name: 'Department',
        path: '/department/:link_name',
        component: Catalog,
        props: true,
    },
    {
        name: 'Group',
        path: '/group/:link_name',
        component: Catalog,
        props: true,
    },
    {
        name: 'Manufacturer',
        path: '/manufacturer/:link_name',
        component: Catalog,
        props: true,
    },
    {
        name: 'Tag',
        path: '/tag/:link_name',
        component: Catalog,
        props: true,
    },
    {
        name: 'Product Tag',
        path: '/product_tag/:link_name',
        component: Catalog,
        props: true,
    },
    {
        path: '/search/:search_term',
        component: Catalog,
        props: true,
    },
    {
        name: 'Product',
        path: '/product/:link_name',
        component: Product,
        props: true,
    },
    {
        path: '/contact',
        component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
        props: true,
    },
    {
        path: '/myaccount',
        component: Account,
        children: [
            {
                path: 'profile',
                name: 'My Profile',
                component: () => import(/* webpackChunkName: "my-profile" */ './views/MyProfile.vue'),
                meta: { restricted: true }
            },
            {
                path: 'orders',
                name: 'My Orders',
                component: () => import(/* webpackChunkName: "my-orders" */ './views/MyOrders.vue'),
                meta: { restricted: true }
            },
            {
                path: 'orders/:order_id',
                name: 'Order Detail',
                component: () => import(/* webpackChunkName: "my-orders-detail" */ './views/MyOrders.vue'),
                props: true,
                meta: { restricted: true }
            },
            {
                path: 'lists',
                component: () => import(/* webpackChunkName: "my-lists" */ './views/MyLists.vue'),
                meta: { restricted: true }
            },
            {
                path: 'lists/:list_id',
                name: 'My List',
                component: () => import(/* webpackChunkName: "my-lists-detail" */ './views/MyLists.vue'),
                props: true,
                meta: { restricted: true }
            },
            {
                path: 'membership',
                component: () => import(/* webpackChunkName: "membership" */ './views/Membership.vue'),
                meta: { restricted: true }
            },
            {
                path: 'waivers',
                name: 'waivers',
                component: () => import(/* webpackChunkName: "waivers" */ './views/Waivers.vue'),
                meta: { restricted: true }
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
                props: true,
                meta: { restricted: true }
            }
        ]
    },
    { path: '/login', redirect: '/myaccount/login' },
    { path: '/waivers', redirect: '/myaccount/waivers' },
    {
        name: 'Range',
        path: '/range',
        component: () => import(/* webpackChunkName: "range" */ './views/Range.vue'),
        props: true
    },
    {
        name: 'Classes',
        path: '/classes',
        component: () => import(/* webpackChunkName: "classes" */ './views/Classes.vue'),
        props: true,
        children: [
            { path: 'calendar', name: 'calendar-view' },
            { path: 'list', name: 'calendar-list' }
        ]
    },
    {
        name: 'Class Detail',
        path: '/classesdetail/:link_name',
        component: () => import(/* webpackChunkName: "class-detail" */ './views/ClassesDetail.vue'),
        props: true
    },
    {
        name: 'Cart',
        path: '/cart',
        component: () => import(/* webpackChunkName: "cart" */ './views/Cart.vue'),
        props: true
    },
    {
        name: 'Transfer Form',
        path: '/transfer-form',
        component: () => import(/* webpackChunkName: "transfer-form" */ './views/TransferForm.vue'),
        props: true
    },
    {
        name: 'Not Found',
        path: '/not-found',
        component: () => import(/* webpackChunkName: "not-found" */ './views/NotFound.vue')
    },
    {
        name: 'Not a Bot',
        path: '/not-a-bot',
        component: () => import(/* webpackChunkName: "not-bot" */ './views/NotBot.vue')
    },
    {
        name: 'Maintenance Mode',
        path: '/maintenance-mode',
        component: () => import(/* webpackChunkName: "maintenance-mode" */ './views/MaintenanceMode.vue')
    },
    {
        path: '/webmaintenance',
        name: 'Web Maintenance',
        component: () => import(/* webpackChunkName: "webmaintenance" */ './views/WebMaintenance.vue'),
    },
    // For the specific routes above to work, this has to be the very last entry
    {
        path: '/:link_name',
        component: () => import(/* webpackChunkName: "content-page" */ './views/ContentPage.vue'),
        props: true,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: (process.env.NODE_ENV == 'production') ? '/' : process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (from.query.template_id && !to.query.template_id) {
        if (to.path === from.path) {
            return
        }
        next({ path: to.path, query: { template_id: from.query.template_id } })
    } else {
        sessionStorage.setItem('return_path', from.path);
        next()
    }
})

export default router
