<template>

<div class="product-container">
	<component v-bind:is="element.name" v-for="(element, index) in getProductSections" :product="product" :featured="featured" :key="index" @addToCart="$emit('addToCart')"></component>
</div>

</template>

<script>
export default {
	name: 'product',
	props: ['config', 'product', 'featured', 'showCatalogLink'],
	data() {
		return {
		}
	},
	created() {
	}
}
</script>

<style lang="scss">
</style>
