<template>

<div class="suggested-item-departments" v-if="suggested_departments.length > 0">
	<h2 class="section-title">{{ title }}</h2>
	<div class="suggested-item-departments-section columns" :class="[ columnsClass ]" :data-section_hash="config.hash">
		<product :product="product" v-for="(product, index) in suggested_departments" :key="'department-' + index"></product>
	</div>
</div>

</template>
SuggestedItemDepartments
<script>
export default {
	name: 'suggested-item-departments',
	props: ['config', 'options'],
	data() {
		return {
			suggested_departments: []
		}
	},
	created() {
		this.fetchData()
	},
	computed: {
		title() {
			return this.getTemplateData.pageSections[this.localIndex].options.title
		},
		localIndex() {
            return this.config.index - 1
        },
		columnsClass() {
			return 'columns-' + this.getTemplateData.pageSections[this.localIndex].options.columns
		}
	},
	methods: {
		fetchData() {
			var data = {
				columns: this.getTemplateData.pageSections[this.localIndex].options.columns,
				rows: this.getTemplateData.pageSections[this.localIndex].options.rows
			}
            if (this.$route.name == "Home") {
                this.updateSiteLoading({ value: 'suggested-item-departments' })
            }
			this.apiCall({ destination: 'get_list_suggested_item_departments', data: data }).then(obj => {
                this.updateSiteLoading({ value: 'suggested-item-departments', remove: true })
                this.suggested_departments = obj.suggested_departments
            }).catch(error => {
                this.updateSiteLoading({ value: 'suggested-item-departments', remove: true })
                console.log(error)
            })
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
