<template>

<transition name="fade">
    <span v-if="localMessage">{{ localMessage }}</span>
</transition>

</template>

<script>
export default {
    name: 'message',
    props: {
        message: {
            type: String,
            default: ''
        },
        delay: {
            type: Number,
            default: 5
        }
    },
    watch: {
        message: function(value) {
            this.localMessage = value
            if (value) {
                this.init()
            }
        }
    },
    data() {
        return {
            localMessage: this.message
        }
    },
    methods: {
        init() {
            setTimeout(() => {
                this.$emit('transitionDone')
            }, this.delay * 1000)
        }
    }
}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
}
</style>
