<template>

<div class="marquee" @mouseover="hover = true" @mouseleave="hover = false" v-if="Object.keys(marquees).length > 0">
	<ul id="marquee" ref="marqueeRef" class="marquees" v-if="!loading">
		<li class="marquee-sizer"><img class="marquee-placeholder" src="https://s3.us-east-2.amazonaws.com/media.connecteddatasolutions.com/marquees/default.jpg" alt="marquee banners"></li>
		<transition-group name="marquees">
			<li v-if="index == marqueesKeys[marqueesIndex]" v-for="(marquee, index) in marquees" :key="'marquee-' + index" class="marquee-sliders">
				<a v-if="marquee.link_url.indexOf('http') > -1" @click="logMarqueeClick(marquee)" :href="marquee.link_url" target="_blank">
					<img :src="marquee.image_src[marqueeSize]" :alt="marquee.link_url + ' banner'" />
				</a>
				<router-link v-else @click="logMarqueeClick(marquee)" :to="marqueeLink(marquee.link_url)">
					<img :src="marquee.image_src[marqueeSize]" :alt="marquee.link_url + ' banner'" />
				</router-link>
			</li>
		</transition-group>
	</ul>
	<div class="marquee-controls-outer">
		<div class="marquee-controls">
			<div class="control" v-for="(key, index) in marqueesKeys" :key="index" @click="marqueesIndex = index">
				<i class="fa-circle" :class="[ (index == marqueesIndex) ? 'fas' : 'far' ]"></i>
			</div>
		</div>
	</div>
</div>

</template>

<script>
export default {
	name: 'marquee',
	props: ['config', 'options', 'link_type', 'link_name'],
	data() {
		return {
			loading: true,
			width: null,
			tries: 0,
			marquees: {},
			marqueesKeys: [],
			interval: null,
			hover: false,
			marqueesIndex: 0
		}
	},
	mounted() {
		this.fetchData()
	},
	beforeDestroy() { 
        clearInterval(this.interval)
	},
	watch: {
		marquees(value) {
            if (value && Object.keys(value).length == 0) return
			this.marqueesKeys = Object.keys(value)
			this.marqueesIndex = 0
            this.interval = setInterval(() => {
                if (this.hover) return
                if (this.marqueesIndex == this.marqueesKeys.length - 1) {
                    this.marqueesIndex = 0
                } else {
                    this.marqueesIndex++
                }
            }, 5000)
        },
		link_name: function (newLinkName, oldLinkName) {
			console.log(this.link_name)
			this.fetchData()
		},
		search_term: function (newLinkName, oldLinkName) {
			this.fetchData()
		}
	},
	computed: {
		computedIndex() {
			return this.config.index - 1
		},
		marqueeSize() {
			this.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
			if (this.width == 0) return 'medium'
			if (this.width > 0 && this.width < 400) {
				return 'medium'
			} else if (this.getTemplateData.siteOptions.fullWidth && this.width > 1100) {
				return 'large'
			} else {
				return 'large'
			}
		}
	},
	methods: {
		fetchData() {
            if (this.$route.name == "Home") {
                this.updateSiteLoading({ value: 'marquee' })
            }
			this.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
			this.apiCall({ destination: 'get_list_marquees', data: { link_type: this.link_type, link_name: this.link_name } }).then(obj => {
                this.updateSiteLoading({ value: 'marquee', remove: true })
				if (Object.keys(obj.marquees).length > 0) {
			    	this.marquees = obj.marquees
					this.loading = false
				}
			}).catch(error => {
                this.updateSiteLoading({ value: 'marquee', remove: true })
			    console.log(error)
			})
		},
		marqueeLink(linkUrl) {
			if (linkUrl.indexOf('myaccount') > -1) {
				if (linkUrl.indexOf('membership') > -1 && !this.getUser.user_name) {
					return '/myaccount/login'
				} else if (linkUrl.indexOf('myaccount/') == 0) {
					return '/myaccount?section=' + linkUrl.replace('myaccount/', '')
				} else {
					return linkUrl
				}
			} else {
				return linkUrl
			}
		},
		logMarqueeClick(marquee) {
			this.apiCall({ destination: 'marquee_click', data: { marquee_id: marquee.marquee_id } })
		}
	}
}
</script>

<style lang="scss" scoped>

.marquee, .catalog.marquee {
    position: relative;
    margin: 0 0 1em 0;
		overflow: hidden;
    .marquee-controls-outer {
        opacity: 0;
        position: absolute;
        bottom: 12px;
        left: 0;
        width: 100%;
        height: 21px;
        text-align: center;
        transition: all 0.3s ease;
    }
    &:hover .marquee-controls-outer { opacity: 1; }
    .marquee-controls {
        display: inline;
        .control {
            cursor: pointer;
            display: inline-block;
            margin: 0 2px;
            color: white;
        }
    }
}
.marquees {
	list-style: none;
	overflow: hidden;
    li {
		img {
			width: 100%;
			height: auto;
		}
    }
	li.marquee-sizer {
		opacity: 0;
		visibility: hidden;
	}
	li.marquee-sliders {
		position: absolute;
		top: 0;
		width: 100%;
	}
}
</style>
