<template>

<div class="main sections home-page" :class="sectionsClass">
	<component
		class="section"
		v-bind:is="element.name"
		:options="element.options"
		:config="{ index: index + 1, hash: element.hash }"
		v-for="(element, index) in getTemplateData.pageSections" :key="index">
	</component>
</div>

</template>

<script>
export default {
	name: 'home',
	data() {
		return {

		}
	},
	mounted() {
	},
	computed:{
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return

			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
