import Vue from 'vue'
import Vuex from 'vuex'

let bid = sessionStorage.getItem('bid')
if (!bid) {
	bid = require('crypto').randomBytes(32).toString('hex')
	sessionStorage.setItem('bid', bid)
}

let environmentName = ''
let developmentEnvironment = (document.location.hostname.indexOf('.test') > -1 || document.location.hostname.indexOf('localhost') > -1)
let sandboxEnvironment = document.location.hostname.indexOf('sandbox') > -1
let hostname = ''
if (developmentEnvironment) {
    hostname = 'cdsapi.test'
    environmentName = 'dev'
} else if (sandboxEnvironment) {
    hostname = 'api.cdssandbox.com'
    environmentName = 'dev'
} else {
    hostname = document.location.hostname + '/api'
    environmentName = 'production'
}
let api = 'https://' + hostname + '/api.php'
let source = 'FRONTEND'

let isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
		bid: bid,
        apiEndpoint: api,
		source: source,
		client: {},
		template_data: {},
		user: {},
		user_detail: {},
		session_user_id: null,
        siteLoading: [],
		openDropdown: null,
		server_name: null,
		shopping_cart_id: null,
		shopping_cart_count: 0,
        items: {},
		filterLists: {},
		filterListsPath: '',
		pagination: {},
		catalogScope: [],
		client_has_memberships: null,
		client_has_range: null,
		client_has_waivers: null,
		credovaUserPublicId: null,
		credovaApprovalAmount: null,
		environment: environmentName,
        btn: '',
		tkn: '',
		entryPath: '',
		minimumAgeConfirmed: false,
		myAccountOptionsSet: false,
		isMobile: isMobile
    },
    mutations: {
        UPDATE_STATE(state, obj) {
			for (let prop in state) {
				if (obj.hasOwnProperty(prop)) {
					state[prop] = obj[prop]
				}
			}
		},
        UPDATE_SITE_LOADING(state, { value, remove }) {
            if (remove) {
                state.siteLoading.splice(state.siteLoading.indexOf(value), 1)
            } else if (state.siteLoading.indexOf(value) == -1) {
                state.siteLoading.push(value)
            }
        },
        UPDATE_FILTER_LISTS(state, filterLists) {
			state.filterLists = JSON.parse(JSON.stringify(filterLists))
		},
		UPDATE_FILTER_LISTS_PATH(state, filterListsPath) {
			state.filterListsPath = filterListsPath
		},
		UPDATE_PAGINATION(state, pagination) {
			state.pagination = JSON.parse(JSON.stringify(pagination))
		},
		UPDATE_CATALOG_SCOPE(state, catalogScope) {
			state.catalogScope = JSON.parse(JSON.stringify(catalogScope))
		},
		UPDATE_CREDOVA_USER_PUBLIC_ID(state, publicId) {
			state.credovaUserPublicId = publicId
			if (publicId) {
				window.localStorage.setItem('credova_user_public_id', publicId)
			} else {
				window.localStorage.removeItem('credova_user_public_id')
			}
		},
		UPDATE_CREDOVA_APPROVAL_AMOUNT(state, credovaApprovalAmount) {
			state.credovaApprovalAmount = credovaApprovalAmount
			if (credovaApprovalAmount) {
				window.localStorage.setItem('credova_approval_amount', credovaApprovalAmount)
			} else {
				window.localStorage.removeItem('credova_approval_amount')
			}
		},
		UPDATE_BTN(state, value) {
			state.btn = value
		},
		UPDATE_ENTRY_PATH(state, value) {
			state.entryPath = value
		},
		UPDATE_MINIMUM_AGE_CONFIRMED(state, value) {
			state.minimumAgeConfirmed = value
		},
		UPDATE_MY_ACCOUNT_OPTIONS_SET(state, value) {
			state.myAccountOptionsSet = value
		}
	},
    getters: {
        getSiteLoading: state => state.siteLoading,
        getClient: state => state.client,
        getTemplateData: state => state.template_data,
        getUser: state => state.user,
        getUserDetail: state => state.user_detail,
        getProductSections: state => state.template_data.productSections,
        getUserId: state => state.session_user_id,
        getServerName: state => state.server_name,
        getShoppingCartId: state => state.shopping_cart_id,
        getShoppingCartCount: state => state.shopping_cart_count,
        getShoppingCartItems: state => state.items,
        getOpenDropdown: state => state.openDropdown,
        getFilterLists: state => state.filterLists,
        getFilterListsPath: state => state.filterListsPath,
        getPagination: state => state.pagination,
        getCatalogScope: state => state.catalogScope,
        getClientHasMemberships: state => state.client_has_memberships,
        getClientHasRange: state => state.client_has_range,
        getClientHasWaivers: state => state.client_has_waivers,
		getCredovaUserPublicId: state => state.credovaUserPublicId,
		getCredovaApprovalAmount: state => state.credovaApprovalAmount,
		getIsDev: state => state.environment == 'dev',
		getBtn: state => state.btn,
		getEntryPath: state => state.entryPath,
		getMinimumAgeConfirmed: state => state.minimumAgeConfirmed,
		getMyAccountOptionsSet: state => state.myAccountOptionsSet,
		getIsMobile: state => state.isMobile
	},
    actions: {
        updateSiteLoading({ commit }, value) {
            commit('UPDATE_SITE_LOADING', value)
        },
        updateFilterLists({ commit }, filterLists) {
			commit('UPDATE_FILTER_LISTS', filterLists)
		},
		updateFilterListsPath({ commit }, filterListsPath) {
			commit('UPDATE_FILTER_LISTS_PATH', filterListsPath)
		},
		updatePagination({ commit }, pagination) {
			commit('UPDATE_PAGINATION', pagination)
		},
		updateCatalogScope({ commit }, catalogScope) {
			commit('UPDATE_CATALOG_SCOPE', catalogScope)
		},
		updateCredovaUserPublicId({ commit }, value) {
			commit('UPDATE_CREDOVA_USER_PUBLIC_ID', value)
		},
		updateCredovaApprovalAmount({ commit }, value) {
			commit('UPDATE_CREDOVA_APPROVAL_AMOUNT', value)
		},
        updateBtn({ commit }, value) {
            commit('UPDATE_BTN', value)
        },
        updateEntryPath({ commit }, value) {
            commit('UPDATE_ENTRY_PATH', value)
        },
		updateMimimumAgeConfirmed({ commit }, value) {
			commit('UPDATE_MINIMUM_AGE_CONFIRMED', value)
		},
		updateMyAccountOptionsSet({ commit }, value) {
			commit('UPDATE_MY_ACCOUNT_OPTIONS_SET', value)
		},
        fetchShoppingCartCount({ dispatch }) {
			dispatch('apiCall', { destination: 'get_shopping_cart_count' }).then(obj => {
				// console.log(obj)
	            // state.shoppingCartCount = obj.shopping_cart_count
			}).catch(err => {
				console.log(err)
			})
		},
        apiCall({ state, commit, dispatch }, parameters) {
			return new Promise((resolve, reject) => {
				// expected parameters: destination, data, headers, callback
				let url = (parameters.destination.indexOf('.') > 0) ? parameters.destination : state.apiEndpoint + '?action=' + parameters.destination
				if (!('data' in parameters)) { parameters.data = {} }
                parameters.data.fd_token = sessionStorage.getItem('fd_token')
				parameters.data.shopping_cart_id = localStorage.getItem('shopping_cart_id')
				parameters.data.source = state.source
				parameters.data.hostname = document.location.hostname
				parameters.data.app_route = document.location.pathname
				parameters.data.bid = state.bid
				let defaultHeaders = {
	                method: 'post',
	                body: JSON.stringify(parameters.data),
	                credentials: 'include'
	            }
	            let headers = parameters.headers || defaultHeaders
	            fetch(url, headers).then(function(response) {
					if (parameters.destination == 'feature_product_click' || parameters.destination == 'marquee_click') {
						reject(response.status)
						return false
					}
					return response.json()
	            }).then(obj => {
					if (obj.user && obj.user.token) {
						sessionStorage.setItem('fd_token', obj.user.token)
					}
		        	if (obj.status == 'login_required' && document.location.href.indexOf('login') == -1) {
						window.localStorage.removeItem('user_type_code')
			        	var location = '/myaccount/login?return=' + encodeURI(document.location.pathname + document.location.search)
		        		document.location.replace(location)
	                } else {
                        window.localStorage.setItem('shopping_cart_id', obj.shopping_cart_id)
	                	if (obj.user) {
		                	window.localStorage.setItem('user_type_code', obj.user.user_type_code)
		                }
	                }
					// Uncomment if using "Apply now" button on Product detail page
					var credovaUserPublicId = window.localStorage.getItem('credova_user_public_id')
					if (credovaUserPublicId) this.dispatch('updateCredovaUserPublicId', credovaUserPublicId)
					var credovaApprovalAmount = window.localStorage.getItem('credova_approval_amount')
					if (credovaApprovalAmount) this.dispatch('updateCredovaApprovalAmount', credovaApprovalAmount)
                    commit('UPDATE_STATE', obj)
                    resolve(obj)
	            }).catch(error => {
	            	console.log(error)
	            	if (error == 'TypeError: Failed to fetch') {
	            		// document.location.reload()
	            	}
	                reject(error)
	            })
	        })
		}
    },
    modules: {
    }
})

export default store

import { mapState, mapGetters, mapActions } from 'vuex'

store.allGetters = Reflect.ownKeys(store.getters)
store.allActions = Reflect.ownKeys(store._actions)

Vue.mixin({
	methods: {
		...mapActions(store.allActions)
	},
	computed: {
		...mapGetters(store.allGetters)
	}
})
