<template>

<div class="wish-list-modal modal">
	<div class="inner">
		<i class="fas fa-times" @click="$emit('cancel')"></i>
		<div v-if="getUser.user_id">
			<div v-if="Object.keys(wishLists).length > 0">
				<h3 style="margin-top: 0;">Add to a saved list:</h3>
				<ul>
					<li class="wish-list-item" v-for="(description, wishListId) in wishLists" @click="addToWishList(wishListId)" :key="'wish-list-' + wishListId">{{ description }}</li>
				</ul>
			</div>
			<div v-if="loading" class="loading" style="font-size: 2em; margin: 1em 0; text-align: center;"><i class="fas fa-spinner fa-spin"></i></div>
			<h3>Create a new saved list:</h3>
			<input type="text" v-model="newWishListDescription" @keyup.enter="createWishList">
			<button class="button action" @click="createWishList()">Create</button>
		</div>
		<div v-else>
			<p style="font-size: 1.25em; margin: 1em 0; text-align: center;">Please log in or create an account<br>to manage your saved lists.</p>
			<router-link to="/myaccount/login" class="button action" @click="$emit('login')">Go To My Account</router-link>
		</div>
	</div>
</div>

</template>

<script>
export default {
	name: 'wish-list-modal',
	props: ['productId'],
	data() {
		return {
			loading: true,
			wishLists: [],
			newWishListDescription: ''
		}
	},
	created() {
		if (this.getUser.user_id) {
			this.fetchWishLists()
		}
	},
	watch: {
		wishLists(newVal) {
			if (newVal) {
				this.newWishListDescription = ''
			}
		}
	},
	methods: {
		fetchWishLists() {
			this.apiCall({ destination: 'get_list_wish_lists' }).then(obj => {
				this.loading = false
				this.wishLists = obj.wish_lists
			}).catch(err => {
				this.loading = false
				console.log(err)
			})
		},
		addToWishList(wishListId) {
			if (this.loading || (!wishListId && !this.newWishListDescription)) return
			this.loading = true
			this.apiCall({ destination: 'add_to_wish_list', data: { wish_list_id: wishListId, product_id: this.productId }}).then(obj => {
				this.loading = false
				if (obj.status == 'success') {
					this.$emit('itemAdded', obj.wish_list)
				}
			}).catch(err => {
				console.log(err)
			})
		},
		createWishList() {
			if (this.loading || !this.newWishListDescription) return
			this.loading = true
			this.apiCall({ destination: 'save_item_new_wish_list', data: { description: this.newWishListDescription, product_id: this.productId }}).then(obj => {
				this.loading = false
				if (obj.status == 'success') {
					this.$emit('itemAdded', obj.wish_list)
				}
				this.wishLists = obj.wish_lists
			}).catch(err => {
				console.log(err)
			})
		}
	}
}
</script>
