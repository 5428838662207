<template>

<div class="product-title" :style="style">
	{{ product.manufacturer || product.description }}<br>
	<div v-if="product.model" class="smaller">{{ product.model }}</div>
	<div v-if="product.caliber" class="smaller">{{ product.caliber }}</div>
</div>

</template>

<script>
export default {
	name: 'product-title',
	props: ['product', 'productSectionsStyle'],
	data() {
		return {
			style: {
				textAlign: '',
				fontSize: ''
			}
		}
	},
	mounted() {
		// console.log(this.style)
	},
	watch: {
		'productSectionsStyle.title.textAlign'(newVal) {
			this.style.textAlign = newVal || 'center'
		},
		'productSectionsStyle.title.fontSize'(newVal) {
			if (newVal) {
				this.style.fontSize = newVal + "em"
			} else {
				this.style.fontSize = null
			}
		}
	},
	computed: {
		// style() {
		// 	var style = {
		// 		textAlign: this.localStyle.textAlign || 'center'
		// 	}
		// 	style.fontSize = this.localStyle.fontSize + "px"
		// 	return style
		// }
	}
}
</script>

<style lang="scss" >
.product-container {
	.product-title {
	@media (max-width: 1024px) {
		font-size: 1rem !important;
	}
	}
}

.product-meta-price {
@media (max-width: 1024px) {
	display: grid;
	.product-meta {
		display: block !important;
		font-size: 0.6rem !important;
	}
	.product-price {
		font-size: 1.5rem !important;
	}
}
}

</style>
